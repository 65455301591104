import React from 'react';

// add space between 

const Info = ({ data: { totalMinted, availableTotalMinted, userCanMint, availibleUserCanMint } }) => {
  return (
    <div className="flex space-x-2">
      <div className="flex-1 bg-white rounded-2xl p-4 sm:p-6">
        <div className="text-xs text-[#888888] font-medium">Total minted tokens</div>
        <div className="sm:text-2xl font-bold">
          {totalMinted} / {availableTotalMinted.toLocaleString('ru-RU')}
        </div>
      </div>


      <div className="flex-1 bg-white rounded-2xl p-4 sm:p-6">
        <div className="text-xs text-[#888888] font-medium">Minted by you</div>
        <div className="sm:text-2xl font-bold">
          {userCanMint} / {availibleUserCanMint.toLocaleString('ru-RU')}
        </div>
      </div>

    </div>
  );
}

export default Info;