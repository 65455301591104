import React from 'react';
import classnames from 'classnames';
import { useCallback } from 'react';

const InputsMint = ({ curentValue, maxValue, onChange, price }) => {

  const handleChange = useCallback((action) => {
    const value = curentValue + action;

    if (value < 0 || value > maxValue) {
      return;
    }

    onChange(value);
  }, [curentValue, maxValue, onChange]);

  return (
    <div className="flex items-center">
      <button
        type="button"
        onClick={() => handleChange(-1)}
        className={classnames(
          "flex justify-center items-center h-14 w-14 tansition bg-[#323232] rounded-2xl text-white transition hover:text-white/60",
          curentValue <= 1 && "cursor-not-allowed pointer-events-none"
        )}
      >
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={classnames(curentValue <= 1 && 'opacity-40')}>
          <path d="M32 24H16" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
      <span className='w-20 flex justify-center items-center text-[40px]'>{curentValue}</span>
      <button
        type="button"
        onClick={() => handleChange(1)}
        className={classnames(
          "flex justify-center items-center h-14 w-14 tansition bg-[#323232] rounded-2xl  text-white transition hover:text-white/80",
          curentValue >= maxValue && "cursor-not-allowed pointer-events-none"
        )}
      >
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={classnames(curentValue >= maxValue && 'opacity-40')}>
          <path d="M24 16V32" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M32 24H16" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>

      <button
        type="button"
        onClick={() => handleChange(maxValue - curentValue)}
        className={classnames(
          "ml-4 flex justify-center items-center h-14 w-14 tansition bg-[#323232] rounded-2xl font-bold text-white transition hover:text-white/80",
          curentValue >= maxValue && "text-white/40 cursor-not-allowed pointer-events-none"
        )}
      >Max</button>
    </div>
  );
};

export default InputsMint;