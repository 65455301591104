import React from 'react';
import { useNetwork, useSwitchNetwork } from 'wagmi';

import { useEffect } from 'react';
import { CHAIN_ID } from '@/constants/settings';

import MintLayout from '@/sections/mint/MintLayout';
import BaseMint from '@/sections/mint/BaseMint';
import Airdrop from '@/sections/mint/Airdrop';
import JoinPatrol from '@/sections/mint/JoinPatrol';

const MintPage = () => {
  const { chain } = useNetwork()
  const { switchNetwork, error } = useSwitchNetwork();

  useEffect(() => {
    if (chain?.id !== CHAIN_ID && switchNetwork) {
      switchNetwork(CHAIN_ID);
    }
  }, [chain, switchNetwork]);

  return (
    <MintLayout>
      <div className="min-h-screen">
        <BaseMint />
        <JoinPatrol />
        {/* <Airdrop /> */}
      </div>
    </MintLayout>
  );
}

export default MintPage;