import React, { useCallback, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { useAccount } from 'wagmi';

import MintCard from './MintCard';
import GoToPatrol from './GoToPatrol';

import useBoundedTokens from '@/hooks/useBoundedTokens';

import useSafeSoulContract from '@/hooks/useSafeSoulContract';

const Airdrop = () => {
  const { address } = useAccount();
  const [isLoaded, setLoaded] = useState(false);
  const [countMintedTokens, setMintedTokens] = useState(0);

  const countBoundedTokens = useBoundedTokens(address);

  const ssContract = useSafeSoulContract();

  const handleBoundTokens = useCallback(async () => {
    if (!address) return;

    const [countMinted] = await Promise.all([
      ssContract.balanceOf(address, 1)
    ]);

    setMintedTokens(Number(countMinted));

    setLoaded(true);
  }, [address, ssContract]);

  useEffect(() => {
    handleBoundTokens();
  }, [address, handleBoundTokens]);

  if (!isLoaded || (countMintedTokens < 1 && countBoundedTokens < 1)) return;

  if (countBoundedTokens > 0) return <GoToPatrol />;

  return <MintCard title={'Join patrol'}>
    <div className='text-center'>
      <div className="font-medium">
        <p>Become a member of Safesoul community</p>
      </div>

      <div className="mt-2">
        <Link
          to={'/mint-activate'}
          className={classnames(
            "flex items-center justify-center w-full rounded-lg py-5 px-10 text-white font-bold transition bg-[#212121] hover:bg-[#212121]/80",
          )}
        >Activate</Link>
      </div>
    </div>
  </MintCard>
};

export default Airdrop;