import React, { useEffect, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { useAccount, useSignMessage } from 'wagmi';
import Countdown from 'countdown-js';

import goToErrorPage from '../../helpers/goToErrorPage';
import client from '../../helpers/apiClient';

import useSafeSoulContract, { useSafeSoulClaim } from '../../hooks/useSafeSoulContract';

const dateEndAirdrop = new Date('2023-01-31T23:59:59.999Z');

const Airdrop = () => {
  const { address, isConnected } = useAccount();
  const ssContract = useSafeSoulContract();

  const message = useMemo(() => `SafeSoul tokenized ${address}`, [address]);

  const { data: signatureUser, isError, isLoading, isSuccess, signMessage } = useSignMessage({
    message
  })

  const [diAnimalCount, setDiAnimalCount] = useState(0);
  const [backSignatures, setBackSignatures] = useState('0xe5a6ca25edea5456d6b3f5cdf2d3962b4379eb95b6d076fc54f05fbf86e5335e049a6b117e59c302431fda0c872b880c9343e4eecc5c75f5991626dcbf22b6e11b');
  const [isClamed, setIsClamed] = useState(true);
  const [airdropCompleted, setAirdropCompleted] = useState(false);
  const [isTransactionStarted, setTransactionStarted] = useState(false);
  const [time, setTime] = useState(0);

  const {
    isSuccess: isSuccessClaim,
    isError: isErrorClaim,
    write
  } = useSafeSoulClaim(diAnimalCount, backSignatures);

  const isEnableClaim = useMemo(() =>
    !isClamed && diAnimalCount > 0,
    [isClamed, diAnimalCount]
  );

  const checkClaimed = useCallback(async () => {
    try {
      const status = await ssContract
        .claimed(address);

      setIsClamed(status);
    } catch (e) {
      console.error(e);
    }
  }, [ssContract, address, setIsClamed]);

  const getCountDiAnimals = useCallback(async () => {
    try {
      const { status, data } = await client.post('/dianimalsCount', {
        address
      });

      const count = status !== 200 ? 0 : data.count;

      // setDiAnimalCount(count);
      setDiAnimalCount(4);
    } catch (e) {
      console.error(e);

      goToErrorPage();
    }
  }, [address, setDiAnimalCount]);

  useEffect(() => {
    if (!address || !ssContract) return;

    checkClaimed();

    getCountDiAnimals();
  }, [getCountDiAnimals, address, ssContract]);

  const claimTokens = useCallback(async () => {
    if (!isEnableClaim) return;

    signMessage();
  }, [isEnableClaim, address]);

  const handleGetSinature = useCallback(async (signatureUser) => {
    setTransactionStarted(true);

    try {
    const { data: { animals_count, signature } } = await client
      .post('/free-mint', {
        signature: signatureUser, message
      });


    setBackSignatures(signature);
    setDiAnimalCount(animals_count);

      write();
    } catch (e) {
      console.error(e);

      goToErrorPage();
    }
  }, [message, setTransactionStarted, setDiAnimalCount]);

  useEffect(() => {
    if (!signatureUser || !isSuccess) return;

    handleGetSinature(signatureUser);

  }, [signatureUser, isSuccess]);

  useEffect(() => {
    if (!isSuccessClaim) return;

    setIsClamed(true);
    setTransactionStarted(false);
  }, [isSuccessClaim]);

  useEffect(() => {
    if (!isErrorClaim) return;

    setTransactionStarted(false);
    setIsClamed(false);
  }, [isErrorClaim]);

  useEffect(() => {
    // Countdown.timer(dateEndAirdrop, ({ days, hours, minutes, seconds }) => {
    //   hours = hours < 10 ? `0${hours}` : hours;
    //   minutes = minutes < 10 ? `0${minutes}` : minutes;
    //   seconds = seconds < 10 ? `0${seconds}` : seconds;

    //   setTime(days ? `${days}d ${hours}:${minutes}:${seconds}` : `${hours}:${minutes}:${seconds}`);
    // }, () => { setAirdropCompleted(true) });
  }, []);

  return !airdropCompleted &&
    (
      isClamed
        ?
        <div className="text-center bg-white rounded-2xl p-6 mt-2">
          <div className="mb-2 text-2xl font-bold">Airdrop claimed</div>
          <div className="font-medium">{diAnimalCount} tokens claimed successfully</div>
          <div className="mt-2">
            <Link
              to="/mint-activate"
              className={classnames(
                "flex items-center justify-center w-full rounded-lg py-5 px-10 text-white font-bold transition bg-[#212121] hover:bg-[#212121]/80",
              )}
            >Activate</Link>
          </div>
        </div>
        :
        <div className="text-center bg-white rounded-2xl p-6 mt-2">
          <div className="mb-2 text-2xl font-bold">Airdrop</div>
          <div className="font-medium">
            <p>Airdrop for Digital Animals NFT holders.</p>
            <p>{diAnimalCount} Digital Animals in your wallet</p>
          </div>

          {
            diAnimalCount > 0 &&
            <div className="mt-2">
              <button
                type='button'
                onClick={claimTokens}
                className={classnames(
                  "flex items-center justify-center w-full rounded-lg py-5 px-10 text-white font-bold transition bg-[#212121] hover:bg-[#212121]/80",
                  { 'cursor-not-allowed pointer-events-none': !isEnableClaim || isTransactionStarted }
                )}
              > {
                  isTransactionStarted
                    ? <>
                      <span className='mx-auto'>Transaction in progress <span className='inline-block animate-blink animation-delay-600'>.</span> <span className='inline-block animate-blink'>.</span> <span className='inline-block animate-blink animation-delay-300'>.</span></span>
                    </>
                    :
                    <>Claim {diAnimalCount} extra tokens</>
                }
              </button>

              <p className='mt-2 text-[#888888] text-xs font-medium'>Receiving an airdrop does not affect the limit, you still can buy up to 20 tokens</p>
            </div >
          }

          <div className='mt-2 text-xs font-medium'>
            <span className='mr-1 text-[#888888]'>End of airdrop:</span>
            <span>{time}</span>
          </div>
        </div>
    )
};

export default Airdrop;