import React, { useMemo, useCallback } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import { useConnectModal } from '@rainbow-me/rainbowkit';

const Wallet = () => {
  const { openConnectModal } = useConnectModal();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect()

  const walletOutput = useMemo(() => {
    if (!isConnected) return '-';

    if (address?.length === 42) {
      return `${address.slice(2, 8)}...${address.slice(-5)}`.toUpperCase();
    }
    return address;
  }, [address, isConnected]);

  const disconnectWallet = useCallback(async () => {
    disconnect();
  }, [disconnect]);

  return (
    isConnected ?
      <div className='flex rounded-lg px-4 py-3 bg-[#F3F3F4] h-16'>
        <div className='flex-1'>
          <div className='text-xs text-[#888888] font-medium'>Wallet</div>
          <div className="text-lg font-medium" title={address}>{walletOutput}</div>
        </div>

        <div>
          <button type='button' className='flex justify-center items-center p-3 h-full rounded-lg text-xs text-black/60 font-bold leading-none transition hover:text-black/60 hover:bg-[#E3E3E3]' onClick={disconnectWallet}>Disconnect</button>
        </div>
      </div>
      :
      <div className='flex items-center rounded-lg px-4 py-4 bg-[#191919]'>
        <div className='flex-1 pr-2'>
          <div className="text-white text-xs font-medium">Connect discord to get Patrol instructions, access to community channels, receive alerts and many more</div>
        </div>

        <div>
          <button type='button' className='flex justify-center items-center py-2 px-3 bg-[#292929] h-full text-xs font-bold transition text-white rounded-lg hover:bg-[#424242]' onClick={openConnectModal}>
            <img src="/assets/rainbow-small.png" alt="" className='h-6 w-6 object-contain mr-2' />
            <span>Connect</span>
          </button>
        </div>
      </div>
  );
};

export default Wallet;