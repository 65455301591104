import React from 'react';
import classnames from 'classnames';

import MintCard from './MintCard';

const BuyOnOpensea = ({ rouded = 'rounded-lg' }) => {
  return (
    <MintCard>
      <div className='text-center'>
        <div className="font-medium">
          <p>Patrol is waiting for you in Discord,<br />
            join us to make web3 safe together</p>
        </div>

        <div className="mt-2">
          <div>
            <a href="https://discord.com/channels/856828604217425941/1062395758151733290" className={classnames("flex items-center justify-center w-full py-5 px-10 text-white font-bold leading-none transition bg-[#212121] hover:bg-[#212121]/80", rouded)} target="_blank" rel="noopener noreferrer" >
              <span className='mr-2.5'>Go Patrol</span>
              <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.317 1.87A19.791 19.791 0 0 0 15.432.355a.074.074 0 0 0-.079.037c-.21.375-.444.865-.608 1.25a18.272 18.272 0 0 0-5.487 0 12.644 12.644 0 0 0-.617-1.25.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 1.87a.07.07 0 0 0-.032.028C.533 6.546-.32 11.08.099 15.558a.082.082 0 0 0 .031.056 19.905 19.905 0 0 0 5.993 3.03.078.078 0 0 0 .084-.028 14.22 14.22 0 0 0 1.226-1.994c.021-.041.001-.09-.041-.106a13.109 13.109 0 0 1-1.872-.892.077.077 0 0 1-.008-.128c.126-.094.252-.192.372-.291a.074.074 0 0 1 .078-.01c3.927 1.792 8.18 1.792 12.061 0a.074.074 0 0 1 .079.009c.12.099.245.198.372.292.044.032.04.1-.006.128-.598.35-1.22.644-1.873.891a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.029 19.839 19.839 0 0 0 6.002-3.03.077.077 0 0 0 .032-.055c.5-5.177-.838-9.674-3.549-13.66a.06.06 0 0 0-.031-.03ZM8.02 12.83c-1.182 0-2.157-1.085-2.157-2.419 0-1.333.956-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.956 2.418-2.157 2.418Zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419 0-1.333.955-2.419 2.157-2.419 1.21 0 2.176 1.096 2.157 2.42 0 1.333-.946 2.418-2.157 2.418Z" fill="#fff" /></svg>
            </a>
          </div>
        </div>
      </div>
    </MintCard>
  );
};

export default BuyOnOpensea;