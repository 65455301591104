import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { navigate } from 'gatsby';
import classnames from 'classnames';
import { useAccount } from 'wagmi';
import { ethers } from 'ethers';

import Info from './Info';
import Wallet from './Wallet';
import Result from './Result';
import InputsMint from './Inputs';
import BuyOnOpensea from './BuyOnOpensea';

import useSafeSoulContract, { useSafeSoulMint } from '@/hooks/useSafeSoulContract';

import goToErrorPage from '@/helpers/goToErrorPage';

import {
  AVAILABLE_TOKENS_TO_MINT,
  AVAILABLE_TOTAL_TOKENS_TO_MINT,
  INTERVAL_TOTAL_MINTED_UPDATE
} from '@/constants/settings'

let timer = null;

const BaseMint = () => {
  const contract = useSafeSoulContract();

  const { address, isConnected } = useAccount();

  const [isLoaded, setLoaded] = useState(false);
  const [userCanMint, setUserCanMint] = useState(null);
  const [totalMinted, setTotalAvailable] = useState(0);
  const [price, setPrice] = useState(0);

  const [countToMint, setCountToMint] = useState(1);
  const [isTransactionStarted, setTransactionStarted] = useState(false);
  const [hashTransaction, setHashTransaction] = useState(null);

  const amountToMint = useMemo(() =>
    Number((countToMint * price).toFixed(4)), [userCanMint, countToMint, price]
  );

  const isMintEnabled = useMemo(() => totalMinted < AVAILABLE_TOTAL_TOKENS_TO_MINT, [totalMinted]);

  const {
    hash,
    isSuccess,
    isError,
    write
  } = useSafeSoulMint(address, countToMint, amountToMint);

  useEffect(() => {
    if (!isConnected) {
      setUserCanMint(null);
      setTotalAvailable(0);
      setLoaded(false);
    }

  }, [isConnected]);

  useEffect(() => {
    if (userCanMint === null) return;

    if (userCanMint === 0) { setCountToMint(0); }
  }, [userCanMint]);

  const getMinted = useCallback(async () => {
    try {
      const result = await contract
        .minted(address);

      return Number(result);
    } catch (e) {
      console.error(e);
      timer && clearInterval(timer);

      return Promise.reject(e);
    }
  }, [address, contract]);

  const getTotalMinted = useCallback(async () => {
    try {
      const result = await contract
        .totalMinted();

      return Number(result);
    } catch (e) {
      console.error(e);
      timer && clearInterval(timer);

      return Promise.reject(e);
    }
  }, [contract]);

  const getPrice = useCallback(async () => {
    try {
      const result = await contract.price();

      return Number(ethers.utils.formatEther(result));
    } catch (e) {
      console.error(e);
      timer && clearInterval(timer);

      return Promise.reject(e);
    }
  }, [contract]);

  useEffect(() => {
    if (!hash) return;

    setHashTransaction(hash);

    if (!isSuccess) return;

    navigate(`/mint-success?hash=${hash}&count=${countToMint}`);
  }, [hash, isSuccess, countToMint]);

  useEffect(() => {
    if (!isError) return;

    setTransactionStarted(false);
  }, [isError]);

  const getMintTokenInfo = useCallback(async () => {
    if (!address) return;

    try {
      const [countMinted, totalMinted, price] = await Promise.all([
        getMinted(),
        getTotalMinted(),
        getPrice()
      ]);

      const userCanMint = Math.min(
        AVAILABLE_TOTAL_TOKENS_TO_MINT - totalMinted,
        AVAILABLE_TOKENS_TO_MINT - countMinted
      );

      setUserCanMint(userCanMint);
      setTotalAvailable(totalMinted);
      setPrice(price);

      setLoaded(true);
    } catch (e) {
      console.error(e);

      timer && clearInterval(timer);

      return Promise.reject(e);
    }
  }, [address, getMinted, getPrice, getTotalMinted, setPrice, setTotalAvailable, setUserCanMint, setLoaded]);

  const mintTokens = useCallback(async () => {
    if (!isConnected || !countToMint || !amountToMint || !isLoaded || isTransactionStarted) return;
    try {
      setTransactionStarted(true);
      write();
    } catch (e) {
      console.error(e);
      setTransactionStarted(false);

      goToErrorPage();
    }
  }, [isConnected, contract, countToMint, amountToMint, isLoaded, isTransactionStarted, write]);

  useEffect(() => {
    if (!contract) return;

    try {
      getMintTokenInfo();
    } catch (e) {
      console.error(e);

      goToErrorPage();
    }

    timer = setInterval(() => {
      try {
        getMintTokenInfo();
      } catch (e) {
        console.error(e);

        goToErrorPage();
      }
    }, INTERVAL_TOTAL_MINTED_UPDATE);

    return () => clearInterval(timer);
  }, [isConnected, contract, getMintTokenInfo]);

  return <>
    <Info data={{
      totalMinted: isLoaded ? totalMinted : '-',
      availableTotalMinted: AVAILABLE_TOTAL_TOKENS_TO_MINT,
      userCanMint: isLoaded ? AVAILABLE_TOKENS_TO_MINT - userCanMint : '-',
      availibleUserCanMint: AVAILABLE_TOKENS_TO_MINT
    }} />

    <div className="bg-white rounded-2xl p-4 sm:p-6 mt-2">

      <Wallet />

      {isMintEnabled ?
        <>
          <div className="mt-8">
            <ul className="text-black/70 font-medium space-y-4 pl-5">
              <li className='before:content-["—"] before:mr-1 before:-ml-5'>Become a member of Safesoul community, mint and activate your token and join Patrol to make web3 safe</li>
              <li className='before:content-["—"] before:mr-1 before:-ml-5'>Every minted token will generate profit for its holder (soon)</li>
            </ul>
          </div>

          <div className="sm:flex mt-8">
            <InputsMint
              curentValue={countToMint}
              maxValue={isLoaded ? userCanMint : 0}
              price={isLoaded ? price : '-'}
              onChange={setCountToMint}
            />
            <div className="ml-auto mt-4 sm:mt-0">
              <Result data={{ amount: amountToMint, count: countToMint, price }} />
            </div>
          </div>

          <div className="mt-8">
            <button
              type='button'
              onClick={mintTokens}
              className={classnames(
                "flex items-center justify-center w-full rounded-lg py-5 px-10 font-bold leading-none transition bg-[#C2E900] hover:bg-[#C2E900]/80",
                {
                  "opacity-50 cursor-not-allowed pointer-events-none": !countToMint || countToMint < 1 || !userCanMint,
                  "cursor-not-allowed pointer-events-none": isTransactionStarted || (hashTransaction)
                }
              )}
            >
              {isTransactionStarted
                ? <>
                  <span className='mx-auto'>Transaction in progress <span className='inline-block animate-blink animation-delay-600'>.</span> <span className='inline-block animate-blink'>.</span> <span className='inline-block animate-blink animation-delay-300'>.</span></span>
                  {hashTransaction &&
                    <a href={`https://goerli.etherscan.io/tx/${hashTransaction}`} target="_blank" className="inline-flex items-center ml-5 py-0.5 px-4 rounded bg-[#2B2B2B] text-xs font-bold text-[#C2E900] pointer-events-auto">view tnx <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className='ml-1'>
                      <path fillRule="evenodd" clipRule="evenodd" d="M6.31014 4.04898C4.42345 4.26293 2.99756 5.85841 2.99609 7.75719V7.99329C3.81604 7.00535 5.02644 6.42463 6.31014 6.40329V7.64915C6.31021 7.82997 6.41353 7.99488 6.57622 8.07381C6.73891 8.15274 6.93239 8.13183 7.07446 8.01997L10.1591 5.58362C10.2687 5.49738 10.3327 5.36561 10.3327 5.22614C10.3327 5.08666 10.2687 4.9549 10.1591 4.86866L7.07446 2.43297C6.93262 2.32062 6.739 2.29934 6.57614 2.37821C6.41329 2.45708 6.30993 2.62218 6.31014 2.80313V4.04898Z" stroke="#C2E900" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10.9994 2.99756H11.6664C13.5081 2.99756 15.0011 4.49056 15.0011 6.33228V11.6678C15.0011 13.5096 13.5081 15.0026 11.6664 15.0026H6.33082C4.4891 15.0026 2.99609 13.5096 2.99609 11.6678V10.3339" stroke="#C2E900" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    </a>
                  }
                </>
                : <span>Mint</span>
              }
            </button>
          </div>
        </>
        :
        <div className="mt-8">
          <div className="text-2xl font-bold text-center">Sold out 🎉</div>

          <div className="mt-8">
            <BuyOnOpensea />
          </div>
        </div>
      }
    </div>
  </>;
};

export default BaseMint;