import React from 'react';

const Result = ({ data: { count, price, amount } }) => {
  return (
    <div className='py-1.5 text-right'>
      <div className="text-xs text-[#888888] font-medium mb-1">
        Total (ETH)
      </div>
      <div className="font-medium">
        {count > 1 && <span className='text-[#888888] mr-2'>{price}×{count}</span>}
        <span>
          {amount}
        </span>
      </div>
    </div>
  );
};

export default Result;